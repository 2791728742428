import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Flex, TextInput, Anchor, Loader } from '@mantine/core';
import Table from '../../../../../components/Table';
import ViewChanger from '../../Components/ViewChanger';
import SourceGrid from '../../Components/SourceGrid';
import InfoPopup from 'components/InfoPopup';
import { trainedAtTableHeader } from '../../Notion/DataView';
import EmptyState from '../../Components/EmptyState';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import GoogleDriveIcon from 'assets/icons/google-drive.svg';
import { formatDate } from '../../../../../utils/formatters';
import { retrainFailureTooltip, selectSourceIcon, truncateName } from 'pages/Integrations/utils';
import { ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS, VIEW_TYPE, PROVIDERS } from 'utils/constants';
import styles from './styles.module.scss';
import Drives from '../Drives';

const DocumentsDataView = (props) => {
  const {
    viewType,
    setViewType,
    documents,
    user,
    pagination,
    pageCount,
    searchValue,
    debouncedValue,
    setSearchValue,
    isLoading,
    setOpenDisclosure,
    metaPayload,
    totalCount
  } = props;
  const { provider } = useParams();
  const formattedRows = documents.map((list) => ({
    id: list.id,
    name: list.name,
    title: truncateName(list.name, 40),
    sourceIcon: selectSourceIcon(list.type) || GoogleDriveIcon,
    url: list.url,
    type: list.type,
    date: list.createdAt,
    uploadBy: <div className={styles.uploaderName}>{list?.uploadedBy || 'User'}</div>,
    status: list.status,
    admin: list.admin,
    lastTrainingStartedAt: list.lastTrainingStartedAt || list.createdAt,
    lastTrainingAttemptedAt: list.lastTrainingAttemptedAt || list.lastTrainingStartedAt,
    lastTrainedOn: formatDate(list.lastTrainingStartedAt),
    trainingFrequency: list.trainingFrequency,
    error: list.error,
    description: list.description,
    retrainError: retrainFailureTooltip(
      list.status,
      list.lastTrainType,
      list.lastTrainingAttemptedAt || list.lastTrainingStartedAt
    ),
    handleOpenSource: () => list.url && window.open(list.url, '_blank'),
    tooltip: list.name.length > 40 && list.name
  }));

  return (
    <>
      <Flex mt={27}>
        <TextInput
          className={styles.input}
          placeholder='Search for sources'
          leftSection={<SearchIcon />}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
        <ViewChanger
          viewType={viewType}
          setViewType={setViewType}
          user={user}
          provider={provider}
          setOpenDisclosure={setOpenDisclosure}
          pagination={pagination}
        />
        {/* Upload entire drive or shared drive */}
        <Drives />
        {provider === PROVIDERS.GOOGLE && (
          <InfoPopup
            title='Disclosure'
            body={
              <>
                Your data is uniquely stored in our database in a non-human readable format.&nbsp;
                <Anchor underline c='primaryGreen.3' onClick={() => setOpenDisclosure(true)}>
                  Learn more.
                </Anchor>
              </>
            }
          />
        )}
      </Flex>
      {documents.length ? (
        viewType === VIEW_TYPE.GRID ? (
          <SourceGrid
            items={formattedRows}
            user={user}
            hideCreatedAt={true}
            sourceIcon={GoogleDriveIcon}
            pagination={pagination}
            pageCount={pageCount}
            isLoading={isLoading}
            metaPayload={metaPayload}
          />
        ) : (
          <Table
            headers={['Name', trainedAtTableHeader, 'Uploaded by', 'Status', '']}
            rows={formattedRows}
            pagination={pagination}
            pageCountFromProp={pageCount}
            pageLimitFromProp={ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS}
            sourceIcon={GoogleDriveIcon}
            totalCount={totalCount}
            metaPayload={metaPayload}
            disableRowClick
          />
        )
      ) : isLoading ? (
        <Flex align='center' justify='center' h='55vh'>
          <Loader />
        </Flex>
      ) : debouncedValue ? (
        <EmptyState hideButton text={`No sources matching "${searchValue}" found.`} />
      ) : (
        <EmptyState hideButton={searchValue} isDisabled={!user.isAdmin} />
      )}
    </>
  );
};

DocumentsDataView.defaultProps = {
  viewType: 'grid',
  documents: []
};

DocumentsDataView.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func.isRequired,
  documents: PropTypes.array,
  user: PropTypes.object.isRequired,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  }).isRequired,
  pageCount: PropTypes.PropTypes.number.isRequired,
  searchValue: PropTypes.PropTypes.string.isRequired,
  setSearchValue: PropTypes.PropTypes.func.isRequired,
  isLoading: PropTypes.PropTypes.bool.isRequired,
  debouncedValue: PropTypes.PropTypes.string.isRequired,
  setOpenDisclosure: PropTypes.PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired
};
export default DocumentsDataView;
