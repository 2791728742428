import { Flex, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const StepGuide = (props) => {
  const { steps } = props;

  if (!steps.length) {
    return null;
  }

  return (
    <Flex direction='column'>
      {/** Add main title in constants at first place  */}
      <Text className={styles.heading}> {steps.length > 0 && steps[0].title}</Text>
      {steps.slice(1).map((step, index) => (
        <Flex key={`${index}-${step.title}`} direction='column'>
          <Flex direction='row' mb='32' mt='32'>
            <div className={styles.circle}>
              <Text size='12px' fw='bolder'>
                {index + 1}
              </Text>
            </div>
            <Text className={styles.text}>{step.title}</Text>
          </Flex>
          <img className={styles.image} src={step.image} />
        </Flex>
      ))}
    </Flex>
  );
};

StepGuide.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      img: PropTypes.node
    })
  ).isRequired
};

export default StepGuide;
