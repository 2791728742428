import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Select, TextInput } from '@mantine/core';
import ViewChanger from 'pages/Integrations/Connector/Components/ViewChanger';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { FILTER_OPTIONS } from '../../../constants';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from './styles.module.scss';

const Header = (props) => {
  const {
    viewType,
    setViewType,
    user,
    teamName,
    teamId,
    filter,
    setFilter,
    pagination,
    searchValue,
    setSearchValue
  } = props;
  const navigate = useNavigate();

  return (
    <Flex mt={27} align='center' justify='space-between' className={styles.headerWrapper}>
      <TextInput
        className={styles.searchInput}
        placeholder='Search for sources'
        leftSection={<SearchIcon />}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <Flex className={styles.dropdownWrapper} align='center' gap={24}>
        <Select
          className='select-style-v2'
          value={filter}
          onChange={(value) => setFilter(value)}
          data={[...FILTER_OPTIONS]}
        />
        <ViewChanger
          viewType={viewType}
          setViewType={setViewType}
          pagination={pagination}
          user={user}
          hideAddSourceButton={true}
          customWrapperStyles={styles.customWrapperStyles}
        />

        <Button
          onClick={() =>
            navigate(
              `${NAVBAR_ROUTES.INTEGRATIONS_PROVIDERS.WEB}/${teamId}/addSource?teamId=${teamId}&teamName=${teamName}&fromButtonClick=true`
            )
          }
          className='primaryBtn'
        >
          Add Source
        </Button>
      </Flex>
    </Flex>
  );
};

Header.propTypes = {
  viewType: PropTypes.string.isRequired,
  setViewType: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  teamName: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired
};

export default Header;
