import React from 'react';
import classNames from 'classnames';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar';
import { Badge, Button, CloseButton, Flex, Tooltip } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { useLogoutMutation } from 'redux/services/user';
import Help from './Help/index.jsx';
import InviteTeammates from './InviteTeammates';
import InstallAlbus from './InstallAlbus';
import Logout from './Logout/index.jsx';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { ReactComponent as IntegrationsIcon } from 'assets/icons/li_book.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/payment.svg';
import { ReactComponent as LogoSmall } from 'assets/logo-small.svg';
import { ReactComponent as AlbusLightLogo } from 'assets/albus-light-logo.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ChatWidgetIcon } from 'assets/icons/chat-widget.svg';
import { ReactComponent as ActivityLogs } from 'assets/icons/activity-logs.svg';
import { NAVBAR_ROUTES } from 'utils/constants';
import { PopupContextProvider } from 'contexts/PopupContext';
import styles from './layout.module.scss';

const Navbar = () => {
  const { user, loading: isUserLoading } = useSelector((state) => state.user);
  const { planInfo, loading: isPlanLoading } = useSelector((state) => state.plan);
  const { collapseSidebar, collapsed, toggleSidebar } = useProSidebar();
  const screen991 = useMediaQuery('(max-width: 991px)');
  const screen560 = useMediaQuery('(max-width: 560px)');
  const [logoutUser] = useLogoutMutation();
  const { pathname = '' } = useLocation();
  const isSlackPlatform = user?.platform === 'slack';

  const closeSideBar = () => {
    if (window.innerWidth <= 992) toggleSidebar();
  };

  const url = new URL(window.location.href);
  const isOnboarding = !!url.searchParams.get('on-boarding');

  return (
    <PopupContextProvider>
      <Sidebar
        data-testid='expandedNavbar'
        width={screen560 ? '100%' : '250px'}
        breakPoint='lg'
        backgroundColor='#182037'
        className={styles.sideNavBar}
        defaultCollapsed={!screen991} //for tablet view show expanded view
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div style={{ flex: 1, marginBottom: '20px' }}>
            <div className={styles.logoContainer}>
              {collapsed ? <LogoSmall /> : <AlbusLightLogo />}
              {screen991 && (
                <CloseButton
                  data-testid='collapseButton'
                  title='Close Menu'
                  size='xl'
                  onClick={closeSideBar}
                  iconSize={20}
                  className={styles.mobileCloseMenuBtn}
                />
              )}
              <div className={styles.navbarCollapseButton}>
                <Button
                  data-testid='sidebarCollapseButton'
                  className={classNames(styles.toggleButton, {
                    [styles.collapsedBtn]: collapsed
                  })}
                  onClick={() => {
                    collapseSidebar();
                  }}
                >
                  &nbsp;
                  <ArrowLeftIcon />
                  &nbsp;
                </Button>
              </div>
            </div>
            <Menu
              className={classNames(styles.navigationList, {
                [styles.navigationCollapsed]: collapsed,
                [styles.hidden]: isOnboarding
              })}
              menuItemStyles={{
                button: {
                  height: 40
                }
              }}
            >
              {!planInfo?.isTrialExpired && (
                <Tooltip label='Ask Albus' disabled={!collapsed} arrowOffset={5} position='right'>
                  <MenuItem
                    active={pathname.includes(NAVBAR_ROUTES.ASK_ALBUS)}
                    component={<Link to={NAVBAR_ROUTES.ASK_ALBUS} />}
                    icon={<SVGIcon height={18} width={18} name={ICONS_LIST.ASK_ALBUS} />}
                    onClick={closeSideBar}
                  >
                    Ask Albus
                  </MenuItem>
                </Tooltip>
              )}

              {user.isAdmin && !planInfo?.isTrialExpired && (
                <Tooltip
                  label='Chat widgets'
                  disabled={!collapsed}
                  arrowOffset={5}
                  position='right'
                  multiline={false}
                >
                  <MenuItem
                    active={pathname.includes(NAVBAR_ROUTES.CHAT_WIDGETS)}
                    component={<Link to={NAVBAR_ROUTES.CHAT_WIDGETS} />}
                    icon={<ChatWidgetIcon />}
                    onClick={closeSideBar}
                  >
                    Chat widgets
                  </MenuItem>
                </Tooltip>
              )}

              {(user?.isAdmin || user?.isTeamAdmin) && !planInfo?.isTrialExpired && (
                <Tooltip
                  label='Wiki'
                  disabled={!collapsed}
                  arrowOffset={5}
                  position='right'
                  multiline={false}
                >
                  <MenuItem
                    active={
                      pathname.includes(NAVBAR_ROUTES.INTEGRATIONS) ||
                      pathname.includes(NAVBAR_ROUTES.FIRST_USER_LOGIN)
                    }
                    component={<Link to={NAVBAR_ROUTES.INTEGRATIONS} />}
                    icon={<IntegrationsIcon />}
                    onClick={closeSideBar}
                  >
                    Wiki
                  </MenuItem>
                </Tooltip>
              )}

              {(user?.isAdmin || user?.isTeamAdmin) &&
                isSlackPlatform &&
                !planInfo?.isTrialExpired && (
                  <Tooltip
                    label='Teams'
                    disabled={!collapsed}
                    arrowOffset={5}
                    position='right'
                    multiline={false}
                  >
                    <MenuItem
                      active={pathname.includes(NAVBAR_ROUTES.TEAMS)}
                      component={<Link to={NAVBAR_ROUTES.TEAMS} />}
                      icon={<SVGIcon height={18} width={18} name={ICONS_LIST.TEAMS} />}
                      onClick={closeSideBar}
                    >
                      Teams
                    </MenuItem>
                  </Tooltip>
                )}

              {isSlackPlatform && user.isAdmin && !planInfo?.isTrialExpired && (
                <Tooltip
                  label='Questions'
                  disabled={!collapsed}
                  arrowOffset={5}
                  position='right'
                  multiline={false}
                >
                  <MenuItem
                    active={pathname.includes(NAVBAR_ROUTES.QUESTIONS)}
                    component={<Link to={NAVBAR_ROUTES.QUESTIONS} />}
                    icon={<SVGIcon height={18} width={18} name={ICONS_LIST.TEAMS_QUESTION} />}
                    onClick={closeSideBar}
                  >
                    Questions
                  </MenuItem>
                </Tooltip>
              )}

              {user.isAdmin && user.analyticsUrl && (
                <Tooltip
                  label='Activity logs'
                  disabled={!collapsed}
                  arrowOffset={5}
                  position='right'
                  multiline={false}
                >
                  <MenuItem
                    active={pathname.includes(NAVBAR_ROUTES.ACTIVITY_LOGS)}
                    component={<Link to={NAVBAR_ROUTES.ACTIVITY_LOGS} />}
                    icon={<ActivityLogs />}
                    onClick={closeSideBar}
                  >
                    <Flex align='center' gap={10}>
                      Activity logs
                      <Badge
                        variant='gradient'
                        gradient={{
                          from: 'rgba(0, 143, 208, 1)',
                          to: 'rgba(97, 41, 207, 1)',
                          deg: 84.63
                        }}
                        classNames={{ label: styles.badgeLabel }}
                      >
                        New
                      </Badge>
                    </Flex>
                  </MenuItem>
                </Tooltip>
              )}

              {!planInfo?.isTrialExpired && (
                <Tooltip
                  label='Settings'
                  disabled={!collapsed}
                  arrowOffset={5}
                  position='right'
                  multiline={false}
                >
                  <MenuItem
                    active={pathname.includes(NAVBAR_ROUTES.SETTINGS)}
                    component={<Link to={NAVBAR_ROUTES.SETTINGS} />}
                    icon={<SettingsIcon />}
                    onClick={closeSideBar}
                  >
                    Settings
                  </MenuItem>
                </Tooltip>
              )}

              {user.isAdmin && !planInfo?.isTrialExpired && (
                <Tooltip
                  label='Billing'
                  disabled={!collapsed}
                  arrowOffset={5}
                  arrowSize={8}
                  withArrow
                  position='right'
                >
                  <MenuItem
                    active={pathname.includes(NAVBAR_ROUTES.BILLING)}
                    component={<Link to={NAVBAR_ROUTES.BILLING} />}
                    icon={<PaymentIcon />}
                    onClick={closeSideBar}
                  >
                    Billing
                  </MenuItem>
                </Tooltip>
              )}
            </Menu>
          </div>

          {user.isAdmin && !planInfo?.isTrialExpired && (
            <div className={classNames(styles.inviteComponent, { [styles.hidden]: isOnboarding })}>
              <InviteTeammates />
            </div>
          )}

          <div className={classNames(styles.helpComponent, { [styles.hidden]: isOnboarding })}>
            <Help isSlackPlatform={isSlackPlatform} />
          </div>

          <div
            className={classNames(
              { [styles.hidden]: isOnboarding },
              'hideAlbusExtensionInstallCta'
            )} // To do: 'hideAlbusExtensionInstallCta' classname to be removed when Slack and Teams CTA are introduced again
          >
            <InstallAlbus isAppConnected={user?.isAppConnected} />
          </div>

          <div
            className={classNames(styles.profileContainer, {
              [styles.profileContainerCollapsed]: collapsed
            })}
          >
            <Logout
              user={isUserLoading ? { name: '', email: '', photo: '' } : user}
              collapsed={collapsed}
              screen991={isPlanLoading ? false : screen991}
              logoutUser={logoutUser}
              styles={styles}
            />
          </div>
        </div>
      </Sidebar>

      <Outlet context={collapsed} />
    </PopupContextProvider>
  );
};

export default Navbar;
