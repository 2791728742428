import PropTypes from 'prop-types';
import { Flex, TextInput, Loader } from '@mantine/core';
import SourceGrid from '../../Components/SourceGrid';
import Table from '../../../../../components/Table';
import ViewChanger from '../../Components/ViewChanger';
import EmptyState from '../../Components/EmptyState/index.jsx';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { trainedAtTableHeader } from '../../Notion/DataView';
import { formatDate } from 'utils/formatters';
import { retrainFailureTooltip, truncateName } from 'pages/Integrations/utils';
import { returnOtherIntegrationsIcon } from 'utils/utilities';
import {
  ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS,
  TRUTO_CONNECTIONS_WITH_PROJECT_NAME,
  VIEW_TYPE,
  APIDECK_INTEGRATIONS
} from 'utils/constants';
import { INTEGRATIONS_TABLE_HEADERS } from '../../../../../utils/constants';
import styles from './styles.module.scss';

const SourcesDataView = (props) => {
  const {
    viewType,
    setViewType,
    sources,
    user,
    isLoading,
    providerName,
    pagination,
    pageCount,
    searchValue,
    setSearchValue,
    debouncedValue,
    totalCount,
    metaPayload
  } = props;
  const formattedRows = sources.map((list) => ({
    id: list.id,
    title: truncateName(list.name ? list.name : list.url, 40),
    name: TRUTO_CONNECTIONS_WITH_PROJECT_NAME.includes(providerName)
      ? list.name
      : `Absorbing knowledge from ${list?.uploadedBy || 'User'}'s account`,
    url: list.url,
    date: formatDate(list.lastTrainingStartedAt),
    uploadBy: <div className={styles.uploaderName}>{list?.uploadedBy || 'User'}</div>,
    status: list.status,
    admin: list.admin,
    trainingFrequency: list.trainingFrequency,
    lastTrainingStartedAt: list.lastTrainingStartedAt || list.createdAt,
    lastTrainingAttemptedAt: list.lastTrainingAttemptedAt || list.createdAt,
    error: list.error,
    description: list.description,
    retrainError: retrainFailureTooltip(
      list.status,
      list.lastTrainType,
      list.lastTrainingAttemptedAt || list.lastTrainingStartedAt
    ),
    handleOpenSource: () => list.url && window.open(list.url, '_blank'),
    tooltip: list.name.length > 40 && list.name
  }));

  const tableHeaders = [...INTEGRATIONS_TABLE_HEADERS];
  tableHeaders[1] = trainedAtTableHeader;

  return (
    <>
      <Flex mt={27} justify='space-between' align='flex-end' className={styles.searchContainer}>
        <TextInput
          className={styles.searchInput}
          placeholder='Search for sources'
          leftSection={<SearchIcon />}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <Flex align='flex-end'>
          <ViewChanger
            viewType={viewType}
            setViewType={setViewType}
            user={user}
            hideAddSourceButton={!formattedRows.length}
            hideReAuthButton={!APIDECK_INTEGRATIONS.includes(providerName)}
            provider={providerName}
            pagination={pagination}
          />
        </Flex>
      </Flex>
      {formattedRows.length ? (
        viewType === VIEW_TYPE.GRID ? (
          <SourceGrid
            items={formattedRows}
            user={user}
            hideCreatedAt={true}
            sourceIcon={returnOtherIntegrationsIcon(providerName)}
            pagination={pagination}
            pageCount={pageCount}
            isLoading={isLoading}
            metaPayload={metaPayload}
          />
        ) : (
          <Table
            headers={tableHeaders}
            rows={formattedRows}
            hideCreatedAt={true}
            pagination={pagination}
            pageCountFromProp={pageCount}
            pageLimitFromProp={ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS}
            isLoading={isLoading}
            sourceIcon={returnOtherIntegrationsIcon(providerName)}
            totalCount={totalCount}
            metaPayload={metaPayload}
            disableRowClick
          />
        )
      ) : isLoading ? (
        <Flex align='center' justify='center' h='55vh'>
          <Loader />
        </Flex>
      ) : debouncedValue ? (
        <EmptyState hideButton text={`No sources matching "${searchValue}" found.`} />
      ) : (
        <EmptyState />
      )}
    </>
  );
};

SourcesDataView.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  sources: PropTypes.array,
  user: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  providerName: PropTypes.string,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  }).isRequired,
  pageCount: PropTypes.PropTypes.number.isRequired,
  searchValue: PropTypes.PropTypes.string.isRequired,
  setSearchValue: PropTypes.PropTypes.func.isRequired,
  debouncedValue: PropTypes.PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired
};

export default SourcesDataView;
