import { Tabs } from '@mantine/core';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import EmptyState from 'pages/Integrations/Connector/Components/EmptyState';
import DataPoints from './DataPoints';
import StepGuide from 'components/StepGiude';
import { CONNECTOR_WISE_DATA_POINTS } from 'pages/ControlPanel/data';
import { getInstallSteps } from 'pages/ControlPanel/utils';
import styles from './styles.module.scss';

const View = ({ conn, handleConnect }) => {
  const navigate = useNavigate();
  const steps = getInstallSteps({ conn, navigate, handleConnect });

  return (
    <div className={styles.leftSectionWrapper}>
      <Tabs
        className='tabs-style-1'
        color='primaryGreen.3'
        classNames={{ panel: styles.panel }}
        defaultValue={steps.length ? 'install-steps' : 'description'}
      >
        <Tabs.List>
          {!!steps.length && <Tabs.Tab value='install-steps'>Getting started</Tabs.Tab>}
          <Tabs.Tab value='description'>What Albus absorbs</Tabs.Tab>
        </Tabs.List>

        {!!steps.length && (
          <Tabs.Panel value='install-steps'>
            <StepGuide steps={steps} />
          </Tabs.Panel>
        )}

        <Tabs.Panel value='description'>
          <DataPoints
            data={CONNECTOR_WISE_DATA_POINTS[conn.name]}
            connectorTitle={conn.title}
            connectorName={conn.name}
          />
          {!CONNECTOR_WISE_DATA_POINTS[conn.name] &&
            (conn.isActive ? (
              <EmptyState
                text='To see what Albus absorbs, get in touch with us.'
                hideButton={true}
                containerStyle={styles.containerStyle}
              />
            ) : (
              <EmptyState
                text='This connection is not available. Reach out to us if you would like this connection with Albus.'
                hideButton={true}
                containerStyle={styles.containerStyle}
              />
            ))}
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

View.defaultProps = {
  handleConnect: null
};

View.propTypes = {
  conn: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    title: PropTypes.bool.isRequired
  }),
  handleConnect: PropTypes.func
};

export default View;
