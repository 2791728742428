import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Flex, Indicator, Menu, Modal, Text, Tooltip } from '@mantine/core';
import View from './View';
import RightSection from './RightSection/index';
import ConnectSourceButton from 'pages/Integrations/Connector/Components/ConnectSourceButton';
import ConfirmationModal from '../ConfirmationModal';
import Button from 'components/Button';
import { NON_TRUTO_CONNECTORS } from 'pages/ControlPanel/constants';
import { ReactComponent as LinkIcon } from 'assets/link-white.svg';
import { ReactComponent as LinkGreenIcon } from 'assets/link.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/3-h-dots.svg';
import { ReactComponent as IIcon } from 'assets/connectors/i-icon.svg';
import styles from './styles.module.scss';

const InstallMenuBtn = ({ connectorName }) => {
  return (
    <div className={styles.installMenuBtn}>
      <p className={styles.installCtaText}>Install</p>
      <Tooltip
        maw={400}
        label={`If you uninstalled Albus from your ${connectorName} dashboard, you need to install Albus to ensure your wiki is up to date.`}
      >
        <div className={styles.tooltipIcon}>
          <IIcon />
        </div>
      </Tooltip>
    </div>
  );
};

InstallMenuBtn.propTypes = {
  connectorName: PropTypes.object.isRequired
};

const ConnectorDetailsModal = (props) => {
  const {
    conn,
    selectedConnector,
    onClose,
    handleConnect,
    handleDisconnect,
    handleEnable,
    handleDisable,
    handleRequest,
    handleAddSource
  } = props;

  const { user } = useSelector((state) => state.user);
  const [opened, setOpened] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const location = useLocation();
  const isOnBoarding = location.pathname.includes('on-boarding');

  function generateButtonText() {
    if (!conn.isActive) {
      return 'Request';
    }
    if (conn.autoConnect) {
      return conn.isConnected ? 'Add source' : 'Enable';
    } else {
      return conn.isConnected ? 'Add source' : 'Connect';
    }
  }

  const buttonText = generateButtonText();

  const clickHandler = () => {
    setLoadingBtn(true);
    switch (buttonText) {
      case 'Enable':
        handleEnable(conn);
        break;
      case 'Connect':
        handleConnect(conn);
        break;
      case 'Disable':
        handleDisable(conn);
        break;
      case 'Disconnect':
        handleDisconnect(conn);
        break;
      case 'Request':
        handleRequest(conn);
        break;
      case 'Add source':
        handleAddSource(conn);
        break;
      default:
        break;
    }
    setLoadingBtn(false);
    setOpened(false);
  };

  function renderRightSideIcon() {
    if (buttonText === 'Connect') {
      return conn.installLink ? <LinkGreenIcon /> : <LinkIcon />;
    }
  }

  function handleEnableCtaClick() {
    if (
      conn.installLink &&
      buttonText !== 'Disable' &&
      buttonText !== 'Disconnect' &&
      buttonText !== 'Add source'
    ) {
      if (conn.title === 'Slack') {
        clickHandler();
      } else {
        setOpened(true);
      }
    } else {
      clickHandler();
    }
  }

  function handleDisconnectClick() {
    if (conn.autoConnect) {
      handleDisable(conn);
    } else {
      handleDisconnect(conn);
    }
  }

  function handleInstallClick() {
    window.open(conn.installLink, '_blank');
  }

  function handleSlackInstallClick() {
    const redirectUrl = isOnBoarding ? '/on-boarding' : '/settings/connections';
    window.open(
      `${import.meta.env.VITE_API_URL}/install?organisationId=${user.organisationId}&userId=${
        user.userId
      }&redirect=${redirectUrl}&connector=slack`,
      '_self'
    );
  }

  const disableCta =
    !!JSON.parse(localStorage.getItem('connectionInitiated'))?.[conn.name] &&
    buttonText === 'Connect';

  return (
    <Modal
      opened={true}
      size='90%'
      onClose={onClose}
      centered
      h='600'
      className={styles.modal}
      classNames={{
        header: styles.modalTitle,
        body: styles.body
      }}
    >
      <div className={styles.header}>
        <Flex direction='row' align='center' gap='sm'>
          <img src={conn.icon} className={styles.connectorLogo} />
          <Text className={styles.connectorTitle}>{conn.title}</Text>
          {conn.tag && (
            <Tooltip
              w={281}
              position='top-start'
              arrowOffset={20}
              label='You will be our pilot customer for this beta connection. Get a direct line with CEO Kartik Mandaville while in beta.'
            >
              <div className={styles.tag}>{conn.tag}</div>
            </Tooltip>
          )}
        </Flex>

        <div className={styles.ctaContainer}>
          {conn.installLink && !conn.isConnected && conn.title !== 'Slack' && (
            <Button mr={16} rightSection={<LinkIcon />} onClick={handleInstallClick}>
              Install
            </Button>
          )}

          {conn.installLink && conn.title === 'Slack' && !user.isAppConnected && (
            <Button mr={16} rightSection={<LinkIcon />} onClick={handleSlackInstallClick}>
              Install
            </Button>
          )}

          {conn.isConnected && buttonText === 'Add source' && (
            <Menu shadow='sm' position='bottom-end'>
              <Menu.Target>
                <Button className={styles.menuBtn}>
                  <MenuIcon />
                </Button>
              </Menu.Target>

              <Menu.Dropdown className={styles.menuDropdown}>
                {conn.installLink && (
                  <Menu.Item onClick={handleInstallClick} className={styles.installCta}>
                    <InstallMenuBtn connectorName={conn.title} />
                  </Menu.Item>
                )}

                <Menu.Item onClick={handleDisconnectClick} className={styles.disconnectCta}>
                  {conn.autoConnect ? 'Disable' : 'Disconnect'}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}

          {NON_TRUTO_CONNECTORS.includes(conn.name) && !conn.isConnected ? (
            <ConnectSourceButton provider={conn.name} />
          ) : (
            <>
              {disableCta ? (
                <Tooltip
                  label={
                    <div className='font-poppins'>
                      Connecting to {conn.title}, this may take a few minutes.
                    </div>
                  }
                  width={200}
                  opened
                  withinPortal={false}
                  position='bottom-end'
                  arrowOffset={20}
                  classNames={{ tooltip: styles.disableCtaTooltip }}
                >
                  <Button
                    onClick={clickHandler}
                    rightSection={renderRightSideIcon()}
                    disabled
                    outlined={conn.installLink}
                    variance='primary'
                    className={styles.disabledBtn}
                  >
                    {buttonText}
                  </Button>
                </Tooltip>
              ) : (
                <>
                  {((user.isAppConnected && user.platform === 'slack' && conn.title === 'Slack') ||
                    conn.title !== 'Slack') && (
                    <Indicator
                      disabled={buttonText !== 'Add source'}
                      size={14}
                      position='top-start'
                      color='orange'
                      processing
                    >
                      <Button
                        onClick={handleEnableCtaClick}
                        loading={loadingBtn}
                        rightSection={renderRightSideIcon()}
                        disabled={selectedConnector === conn.name}
                        outlined={
                          buttonText !== 'Add source' && conn.title !== 'Slack' && conn.installLink
                        }
                        variance='primary'
                      >
                        {buttonText}
                      </Button>
                    </Indicator>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <div className={styles.content}>
        <View conn={conn} handleConnect={clickHandler} />
        <RightSection conn={conn} />
      </div>
      {opened && (
        <ConfirmationModal
          conn={conn}
          selectedConnector={selectedConnector}
          installCta={handleInstallClick}
          connectCta={clickHandler}
          onClose={() => setOpened(false)}
        />
      )}
    </Modal>
  );
};

ConnectorDetailsModal.propTypes = {
  conn: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleEnable: PropTypes.func.isRequired,
  handleConnect: PropTypes.func.isRequired,
  handleDisconnect: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  handleDisable: PropTypes.func.isRequired,
  selectedConnector: PropTypes.string.isRequired,
  handleAddSource: PropTypes.func.isRequired
};

export default ConnectorDetailsModal;
