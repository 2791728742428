import { Flex, Text, Divider } from '@mantine/core';
import PropTypes from 'prop-types';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick.svg';
import styles from './styles.module.scss';

const DataPoints = ({ data, connectorName, connectorTitle }) => {
  const categories = Object.keys(data ?? {});

  if (!categories.length) {
    return null;
  }

  return (
    <Flex direction='column' className={styles.container}>
      {connectorName === 'google' ? (
        <>
          {categories.map((cat) => {
            return (
              <Flex key={cat} direction='column'>
                <Text fw={500} mb={12} mt={10}>
                  {cat}
                </Text>
                <Flex gap={16} direction='row' wrap='wrap'>
                  {data[cat].map((point, index) => (
                    <Flex align='center' key={`${cat}-${index}`} gap={8}>
                      {point.icon === 'cross' ? <CrossIcon /> : <TickIcon />}
                      <Text className={styles.dataPoint}>{point.text || point}</Text>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            );
          })}
        </>
      ) : (
        <>
          <Text fw={800} mb={24}>
            Albus absorbs the following information from your {connectorTitle} account.
          </Text>
          {categories.map((cat, index, array) => {
            return (
              <Flex key={cat} direction='column'>
                <Text className={styles.title}>{cat}</Text>
                <Flex gap={16} direction='row' wrap='wrap'>
                  {data[cat].map((point) => (
                    <Flex align='center' key={point} gap={6}>
                      <TickIcon />
                      <Text className={styles.dataPoint}>{point}</Text>
                    </Flex>
                  ))}
                  {/**Hide divider for last category */}
                  {index !== array.length - 1 && <Divider className={styles.divider} size='sm' />}
                </Flex>
              </Flex>
            );
          })}
        </>
      )}
    </Flex>
  );
};

DataPoints.propTypes = {
  data: PropTypes.object,
  connectorTitle: PropTypes.string,
  connectorName: PropTypes.string
};

export default DataPoints;
