import PropTypes from 'prop-types';
import { Modal, Title, Text, Flex, Button, Tooltip } from '@mantine/core';
import { useSelector } from 'react-redux';
import { stringDecoder } from 'utils/helpers';
import { truncateName } from 'pages/Integrations/utils';
import styles from './remove-source.module.scss';

const RemoveSource = (props) => {
  const { opened, onClose, handledelete, item, disableButton } = props;
  const { user } = useSelector((state) => state.user);
  const sourceName = item.originalName || item.name || item.name?.props?.children;

  if (!item) {
    return null;
  }
  return (
    <Modal
      centered
      opened={opened}
      size={550}
      onClose={onClose}
      title={<Title className={styles.title}>Remove source</Title>}
      onClick={(e) => e.stopPropagation()}
    >
      <Title className={styles.question} order={6}>
        Are you sure you want to remove the source -
        <Tooltip
          label={sourceName}
          disabled={sourceName.length < 50}
          maw={1000}
          classNames={{ tooltip: styles.tooltipText }}
        >
          <span>
            <b>{truncateName(stringDecoder(sourceName), 50)}</b> ?
          </span>
        </Tooltip>
      </Title>
      <Text className={styles.noteContent}>
        <span className={styles.noteTitle}>Note:</span>&nbsp; when you remove a source, all
        information that Albus had trained on from it will be removed and Albus will not answer from
        this source.
      </Text>

      {user._id !== item?.admin?._id && (
        <Text className={styles.noteContent}>
          <span className={styles.noteTitle}>Note:</span> this source was added by{' '}
          {item?.admin?.name ? item.admin.name : 'an other admin'}. I will inform them when you
          remove it.
        </Text>
      )}

      <Flex align='center' justify='flex-end' style={{ width: '100%', marginTop: '30px' }}>
        <Button
          variant='outline'
          color='red'
          className={styles.button}
          onClick={onClose}
          disabled={disableButton}
        >
          Cancel
        </Button>
        <Button
          color='red'
          className={styles.button}
          onClick={handledelete}
          disabled={disableButton}
        >
          Remove
        </Button>
      </Flex>
    </Modal>
  );
};

RemoveSource.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handledelete: PropTypes.func.isRequired,
  item: PropTypes.object,
  disableButton: PropTypes.bool
};

export default RemoveSource;
