import { Title } from '@mantine/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as AnnouncementIcon } from 'assets/icons/notification.svg';
import CloseIcon from 'assets/icons/close-icon.svg';
import styles from './styles.module.scss';

const Announcement = (props) => {
  const { title, textCopy, handleClose, customClass, showCloseCta } = props;

  return (
    <div className={classNames(styles.notificationContainer, customClass)}>
      <AnnouncementIcon />
      <div className={styles.content}>
        <Title className={styles.title} order={4}>
          {title}
        </Title>
        <div className={styles.text} data-testid='announcement-text'>
          {textCopy}
        </div>
      </div>
      {showCloseCta && <img onClick={handleClose} src={CloseIcon} className={styles.closeIcon} />}
    </div>
  );
};

Announcement.defaultProps = {
  title: 'Sources',
  textCopy:
    'I study, absorb and remember content from sources added here. Once my training is complete, I respond to employee queries with context relevant to you.',
  handleClose: () => null,
  customClass: '',
  showCloseCta: false
};

Announcement.propTypes = {
  title: PropTypes.string,
  textCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleClose: PropTypes.func,
  customClass: PropTypes.string,
  showCloseCta: PropTypes.bool
};
export default Announcement;
