import { toast } from 'react-toastify';
import { current } from '@reduxjs/toolkit';
import api from './api';
import { END_POINT } from 'utils/apiEndpoints';
import { PROVIDERS } from 'utils/constants';
import { handleApiError, showToastMessage } from 'utils/helpers';
import cloneDeep from 'lodash.clonedeep';

export const integrationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    //fetch all providers data
    fetchProviders: build.query({
      providesTags: ['Providers'],
      query: (params) => ({
        url: END_POINT.INTEGRATIONS,
        method: 'GET',
        params
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error('Failed to fetch integrations.');
        }
      },
      transformErrorResponse: (response) => {
        toast.error(response?.data?.message);
      }
    }),

    //disconnect provider
    disconnectProvider: build.mutation({
      query: (provider) => ({
        url: `${END_POINT.INTEGRATIONS}/${provider}`,
        method: 'DELETE'
      }),
      transformErrorResponse: () => {
        toast.error('Could not disconnect the integration. Please try again later.');
      },
      invalidatesTags: ['Providers', 'User']
    }),

    //fetch provider wise sources
    fetchSources: build.query({
      providesTags: ['SourcesByProvider'],
      query: (payload) => ({
        url: `${END_POINT.INTEGRATIONS}/${payload?.provider}`,
        method: 'GET',
        params: payload?.params
      }),
      transformResponse: (response, meta, args) => {
        if (response.ok) {
          return args?.params?.page || args?.params?.q ? response : response.data;
        } else {
          toast.error('Failed to fetch sources.');
        }
      },
      transformErrorResponse: (error) => {
        toast.error(error?.data?.message);
        return error;
      }
    }),
    //add sources
    addSources: build.mutation({
      query: (payload) => ({
        url: `${END_POINT.INTEGRATIONS}/${payload.provider}`,
        method: 'POST',
        body: payload.body,
        params: payload.params
      }),
      transformResponse: (res, meta) => {
        // We don't want to show toast for google sources as it's already handled in the component
        if (res?.ok && !meta.request.url.includes(PROVIDERS.GOOGLE)) {
          showToastMessage(res.message, meta.response.status === 202);
        }
        return res;
      },
      transformErrorResponse: (res, meta) => {
        if (!meta.request.url.includes(PROVIDERS.WEB)) {
          toast.error(res.data.message);
        }
        return res.data;
      },
      invalidatesTags: ['SourcesByProvider', 'Providers']
    }),

    //refresh added sources
    refreshSources: build.mutation({
      query: (payload) => ({
        url: `${END_POINT.INTEGRATIONS}/${payload.provider}`,
        method: 'PUT'
      })
    }),

    //delete added source
    disconnectSource: build.mutation({
      query: (payload) => ({
        url: `${END_POINT.INTEGRATIONS_SOURCE}/${payload.source.id}`,
        method: 'DELETE',
        params: payload.params
      }),
      transformErrorResponse: (response) => {
        let errorMessage = 'Could not delete the source. Please try again later.';
        if (response?.status === 'FETCH_ERROR') {
          errorMessage = 'There seems to be a network issue on your end, please try again';
        }
        toast.error(errorMessage);
      },
      invalidatesTags: ['Providers']
    }),

    updateDescription: build.mutation({
      query: (body) => {
        return {
          url: END_POINT.INTEGRATOINS_DESCRIPTION,
          method: 'PATCH',
          body
        };
      }
    }),

    updateSource: build.mutation({
      query: (payload) => ({
        url: `${END_POINT.INTEGRATIONS_SOURCE}/${payload.id}`,
        method: 'PUT',
        payload: { ...payload.body }
      })
    }),

    fetchSubDocuments: build.query({
      providesTags: ['SubDocuments'],
      query: (payload) => ({
        url: `${END_POINT.INTEGRATIONS_SUB_DOCUMENTS}/${payload.docId}`,
        method: 'GET',
        params: payload?.params
      }),
      transformErrorResponse: (response) => {
        toast.error(response?.data?.message);
      }
    }),
    crawlSource: build.mutation({
      query: (id) => ({
        url: END_POINT.INTEGRATIONS_SOURCE_CRAWL,
        method: 'POST',
        params: id
      }),
      invalidatesTags: ['SubDocuments']
    }),
    trainSources: build.mutation({
      query: (body) => ({
        url: END_POINT.INTEGRATIONS_SOURCES_TRAIN,
        method: 'PATCH',
        body
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response?.ok) {
            dispatch(
              api.util.updateQueryData('fetchSources', { ...body.meta }, (draft) => {
                const cacheData = current(draft);
                let sourcesCopy = cloneDeep(cacheData.data);

                const index = sourcesCopy.findIndex(
                  (source) => source.id === body.documentMongoIds[0]
                );
                if (index != -1) {
                  if (body.freq === 'now') {
                    sourcesCopy[index] = {
                      ...sourcesCopy[index],
                      status: 'processing'
                    };
                  } else {
                    sourcesCopy[index] = {
                      ...sourcesCopy[index],
                      trainingFrequency: body.retrain
                    };
                  }
                }

                return { ...cacheData, data: sourcesCopy };
              })
            );
          }
          return Promise.resolve();
        } catch (error) {
          return Promise.resolve();
        }
      },

      transformErrorResponse: (response) => {
        let errorMessage =
          response?.data?.message || 'Could not retrain the source. Please try again later.';
        if (response?.status === 'FETCH_ERROR') {
          errorMessage = 'There seems to be a network issue on your end, please try again';
        }
        toast.error(errorMessage);
        return response.data;
      }
    }),
    modifySource: build.mutation({
      query: (body) => ({
        url: END_POINT.INTEGRATIONS_SOURCE,
        method: 'PATCH',
        body
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.ok) {
            dispatch(
              api.util.updateQueryData('fetchSources', { ...body.meta }, (draft) => {
                const cacheData = current(draft);
                let sourcesCopy = cloneDeep(cacheData.data);

                const index = sourcesCopy.findIndex(
                  (source) => source.id === body.documentMongoIds[0]
                );
                if (index != -1) {
                  sourcesCopy[index].name = body.name;
                }
                return { ...cacheData, data: sourcesCopy };
              })
            );
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      },

      transformErrorResponse: (response) => {
        toast.error(response?.data?.message);
      }
    })
  })
});

export const {
  useFetchProvidersQuery,
  useDisconnectProviderMutation,
  useFetchSourcesQuery,
  useAddSourcesMutation,
  useRefreshSourcesMutation,
  useDisconnectSourceMutation,
  useUpdateDescriptionMutation,
  useUpdateSourceMutation,
  useAddWebSourceMutation,
  useFetchSubDocumentsQuery,
  useLazyFetchSubDocumentsQuery,
  useCrawlSourceMutation,
  useTrainSourcesMutation,
  useModifySourceMutation
} = integrationsApi;

export default integrationsApi;
