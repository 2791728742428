import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import { useFetchSourcesQuery } from 'redux/services/integrations';
import Header from './Header';
import MessageEmptyState from './MessageEmptyState';
import ChannelsDataView from './ChannelsDataView';
import MessageDataView from './MessageDataView';
import EmptyState from '../Components/EmptyState';
import { PROVIDERS, ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS, NAVBAR_ROUTES } from 'utils/constants';
import { SOURCE_TYPES } from 'pages/Integrations/utils';

const SlackSources = (props) => {
  const { user, viewType, setViewType } = props;

  const navigate = useNavigate();
  const { teamId } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue] = useDebouncedValue(searchValue, 500);
  const [page, onChange] = useState(1);
  const [sourceType, setSourceType] = useState(SOURCE_TYPES.CHANNELS);

  const payload = {
    provider: PROVIDERS.SLACK,
    params: {
      teamId,
      page: debouncedValue ? undefined : page,
      limit: debouncedValue ? undefined : ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS,
      q: debouncedValue ? debouncedValue : undefined,
      type: sourceType,
      sort: 'lastTrainingStartedAt'
    }
  };
  const {
    data: { data: sources = [], isAppUpdated, totalCount = 0 } = {},
    isLoading,
    isFetching,
    error
  } = useFetchSourcesQuery(
    { ...payload },
    {
      refetchOnMountOrArgChange: true
    }
  );

  //When Albus is removed from the Slack app configuration, wait for 2 sec on the Slack connection page to show a toast message before redirecting to the integrations page.
  if (error && !error?.data?.isAppConnected) {
    setTimeout(() => {
      navigate(NAVBAR_ROUTES.INTEGRATIONS);
    }, 2000);
  }

  const pagination = usePagination({
    page,
    onChange
  });

  function renderView() {
    if (!sources.length && !isLoading) {
      if (sourceType === SOURCE_TYPES.CHANNELS || debouncedValue.length) {
        return (
          <EmptyState
            sourceType={sourceType}
            isDisabled={!user.isAdmin}
            showUpdateModal={false}
            text={
              debouncedValue.length && !sources?.length
                ? `No ${
                    sourceType === SOURCE_TYPES.CHANNELS ? 'sources' : 'messages'
                  } matching "${searchValue}" found.`
                : undefined
            }
            hideButton={debouncedValue.length && !sources?.length}
          />
        );
      }
      return <MessageEmptyState />;
    }
    if (sourceType === SOURCE_TYPES.CHANNELS) {
      return (
        <ChannelsDataView
          viewType={viewType}
          setViewType={setViewType}
          channels={sources}
          user={user}
          showUpdateModal={!isAppUpdated}
          isLoading={isLoading || isFetching}
          pagination={pagination}
          totalCount={totalCount}
          pageCount={Math.ceil(totalCount / ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS)}
          metaPayload={payload}
        />
      );
    }
    return (
      <MessageDataView
        viewType={viewType}
        setViewType={setViewType}
        messages={sources}
        user={user}
        showUpdateModal={!isAppUpdated}
        isLoading={isLoading || isFetching}
        pagination={pagination}
        totalCount={totalCount}
        pageCount={Math.ceil(totalCount / ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS)}
        metaPayload={payload}
      />
    );
  }

  return (
    <>
      <Header
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        viewType={viewType}
        setViewType={setViewType}
        user={user}
        showUpdateModal={!isAppUpdated}
        sourceType={sourceType}
        pagination={pagination}
        setSourceType={(value) => {
          //whenever source type changes, pagintation will be reset
          onChange(1);
          setSourceType(value);
        }}
      />
      {renderView()}
    </>
  );
};

SlackSources.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  user: PropTypes.object
};
export default SlackSources;
