import { useLocation } from 'react-router-dom';
import { useGetSettingsQuery } from 'redux/services/settings';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from './styles.module.scss';

const AutoSuggest = () => {
  const location = useLocation();
  const isAutoAnswerRoute = location.pathname === NAVBAR_ROUTES.SETTINGS_TABS.AUTO_ANSWER;

  if (!isAutoAnswerRoute) {
    return null;
  }

  const { data: settings } = useGetSettingsQuery();

  if (!settings?.autoSuggest?.enabled) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <p>
        Auto-answer has been disabled while you beta-test the new&nbsp;
        <strong>&apos;Auto-suggest&apos;</strong> feature, which prompts teammates to ask Albus when
        he detects questions on channels.
      </p>
    </div>
  );
};

export default AutoSuggest;
