const themeColors = {
  brand: [
    '#e0f0ff',
    '#b8d5fa',
    '#8ebef1',
    '#63a8e8',
    '#3994e0',
    '#1f70c6',
    '#134d9b', // var(--mantine-color-brand-6)
    '#082f70', // var(--mantine-color-brand-7)
    '#001746',
    '#00061d'
  ],
  primaryBlue: [
    '#D1F3FF', // var(--mantine-color-primaryBlue-0)
    '#77DCFF',
    '#33C6FF',
    '#08B0F9', // var(--mantine-color-primaryBlue-3)
    '#008FD0', // var(--mantine-color-primaryBlue-4)
    '#0071A7',
    '#005882',
    '#004565',
    '#00364F',
    '#002A3E'
  ],
  secondaryBlue: [
    '#B9F6FF',
    '#84E8F8', // var(--mantine-color-secondaryBlue-1)
    '#3FE6FF',
    '#00EAFF',
    '#00CEF1',
    '#00ADCA',
    '#0091AA',
    '#007A8F'
  ],
  primaryGreen: [
    '#DBFFEA',
    '#67F9A4',
    '#1DE771',
    '#1CA555', // var(--mantine-color-primaryGreen-3)
    '#0C7136',
    '#034E22',
    '#003616',
    '#00250E',
    '#001809',
    '#001006',
    '#1ca5554d' // var(--mantine-color-primaryGreen-10)
  ],
  warningOrange: [
    '#FFF3DB',
    '#FFE1A6',
    '#FFD176',
    '#FFC24F',
    '#FBB040' // var(--mantine-color-warningOrange-4)
  ],
  errorRed: [
    '#FFC8C8',
    '#FF9595',
    '#FF6767',
    '#FF5252', // var(--mantine-color-errorRed-3)
    '#D30000'
  ],
  grayscale: [
    '#D2D2D2', // var(--mantine-color-grayscale-0)
    '#838383', // var(--mantine-color-grayscale-1)
    '#525252', // var(--mantine-color-grayscale-2)
    '#333333', // var(--mantine-color-grayscale-3)
    '#202020',
    '#141414',
    '#080808',
    '#030303', // var(--mantine-color-grayscale-7)
    '#010101' // var(--mantine-color-grayscale-8)
  ],
  white: ['#FFFFFF'] //var(--mantine-color-white-0)
};

export default themeColors;
