import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import Header from './Components/Header/View';
import SourcesDataView from './Components/SourcesDataView/View';
import { ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS } from 'utils/constants';

const DataView = (props) => {
  const {
    viewType,
    setViewType,
    user,
    teamName,
    teamId,
    loading,
    sources,
    totalCount,
    pagination,
    debouncedValue,
    searchValue,
    setSearchValue,
    filter,
    setFilter,
    metaPayload
  } = props;

  const navigate = useNavigate();

  function handleManageSourcesClick(item) {
    const sourceName = encodeURIComponent(item.originalName || item.name);
    if (item.childCounts.total > 0 || item.status === 'error') {
      navigate(
        `/integrations/web/${teamId}/manageSources?docId=${item.id}&teamName=${teamName}&sourceName=${sourceName}&hasChildSources=${item.isParent}`
      );
    }
  }

  return (
    <>
      <Header
        viewType={viewType}
        setViewType={setViewType}
        user={user}
        teamId={teamId}
        teamName={teamName}
        filter={filter}
        setFilter={setFilter}
        pagination={pagination}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      <SourcesDataView
        viewType={viewType}
        setViewType={setViewType}
        sources={sources}
        user={user}
        isLoading={loading}
        filter={filter}
        pagination={pagination}
        pageCount={Math.ceil(totalCount / ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS)}
        debouncedValue={debouncedValue}
        handleManageSourcesClick={handleManageSourcesClick}
        metaPayload={metaPayload}
      />
    </>
  );
};

DataView.propTypes = {
  viewType: PropTypes.string.isRequired,
  setViewType: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  teamName: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  sources: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  pagination: PropTypes.object.isRequired,
  debouncedValue: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: {
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    }
  }).isRequired
};

export default DataView;
