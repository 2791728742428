import { Button, Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const RequestModal = (props) => {
  const { setShowRequestModal, handleBookCall, handleChatOnSlack, isIntegration } = props;

  const integrationTypeCopy = isIntegration ? 'app' : 'connection';

  return (
    <Modal centered opened={true} size={695} radius={4} onClose={() => setShowRequestModal(false)}>
      <div className={styles.wrapper}>
        <h2>We hear you</h2>
        <p>
          If this {integrationTypeCopy} is important to you, talk to your account manager. They will
          work with you and prioritize this {integrationTypeCopy} for you.
        </p>
        <Button className='primaryBtn' onClick={handleBookCall}>
          Book a call with your account manager
        </Button>
        <footer onClick={handleChatOnSlack}>Chat on Slack</footer>
      </div>
    </Modal>
  );
};

RequestModal.defaultProps = {
  isIntegration: false
};

RequestModal.propTypes = {
  setShowRequestModal: PropTypes.func.isRequired,
  handleBookCall: PropTypes.func.isRequired,
  handleChatOnSlack: PropTypes.func.isRequired,
  isIntegration: PropTypes.bool
};

export default RequestModal;
