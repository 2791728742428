import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMediaQuery } from '@mantine/hooks';
import { Loader } from '@mantine/core';
import WelcomeModal from 'components/Modals/WelcomeModal';
import TutorialModal from 'components/Modals/TutorialModal';
import TrialExpired from './TrialExpired';
import Header from './Header';
import MobileHeader from './MobileHeader';
import AutoSuggest from './AutoSuggest';
import { useGetPlanQuery } from 'redux/services/plan';
import { useSwitchCompanyMutation } from 'redux/services/settings';
import { useGetUserCompaniesQuery, useUpdateUserMutation } from 'redux/services/user';
import { NAVBAR_ROUTES, TUTORIAL_STATUS } from 'utils/constants';
import styles from './layout.module.scss';

const Layout = (props) => {
  const { children, pageTitle, className } = props;
  useGetPlanQuery();
  const { user, loading } = useSelector((state) => state.user);
  const screen991 = useMediaQuery('(max-width: 991px)');
  const { planInfo, loading: isPlanLoading } = useSelector((state) => state.plan);
  const [showPlanExpiredModal, setShowPlanExpiredModal] = useState(
    !isPlanLoading && !planInfo.hasSubscription && planInfo.trialExpired
  );
  const { data } = useGetUserCompaniesQuery();
  const [switchCompany] = useSwitchCompanyMutation();
  const [rearrangedCompanies, setRearrangedCompanies] = useState([]);
  const [updateUser] = useUpdateUserMutation();
  const url = new URL(window.location.href);
  const showTutorialModal = !!url.searchParams.get('showTutorialModal');
  const isOnBoarding = !!url.searchParams.get('on-boarding');
  const showWelcomeModal = !!url.searchParams.get('showWelcomeModal');
  const isSlackPlatform = user?.platform === 'slack';
  const navigate = useNavigate();
  const hideTutorial = true;

  useEffect(() => {
    if (data?.length && user?.organisationId) {
      const currentCompany = data.filter((org) => org._id === user.organisationId) || [];
      const otherCompanies = data.filter((org) => org._id !== user.organisationId) || [];
      setRearrangedCompanies([...currentCompany, ...otherCompanies]);
    }
  }, [data, user]);

  useEffect(() => {
    if (planInfo.isTrialExpired) {
      setShowPlanExpiredModal(true);
    }
  }, [planInfo]);

  useEffect(() => {
    if (
      !isOnBoarding &&
      Object.values(user).length &&
      user.isInstaller &&
      user.isAdmin &&
      user.onboarding?.installer?.status !== 'complete' &&
      user.onboarding?.installer?.status !== 'not_to_be_shown'
    ) {
      //on-boarding will be shown to new admin installer who has not completed onboarding
      navigate(NAVBAR_ROUTES.ON_BOARDING);
    }
  }, [user]);

  const handleCompanySwitch = async (id) => {
    const { data } = await switchCompany({
      id
    });

    if (data?.ok) {
      window.open(
        `${import.meta.env.VITE_BASE_URL}${NAVBAR_ROUTES.ASK_ALBUS}?token=${data.data.token}`,
        '_self'
      );
    }
  };

  function closeWelcomeModal() {
    updateUser({
      tutorialStatus: TUTORIAL_STATUS.STARTED
    });

    navigate(`${window.location.pathname}?showTutorialModal=true`);
  }

  function closeTutorialModal() {
    if (user?.tutorialStatus === TUTORIAL_STATUS.STARTED) {
      //new user
      updateUser({
        tutorialStatus: TUTORIAL_STATUS.MINIMISED
      });
      navigate(`${window.location.pathname}?showExploreMorePopup=true`);
      return;
    }

    navigate(window.location.pathname);
  }

  return (
    <div className={classNames(styles.layout, className)}>
      {loading ? (
        <div className={styles.loader_container}>
          <Loader />
        </div>
      ) : (
        <div className={styles.pageWrapper}>
          <AutoSuggest />
          {screen991 && <MobileHeader user={user} />}
          <div id='scroll-target' className={styles.pageContainer}>
            <Header
              user={user}
              pageTitle={pageTitle}
              companies={rearrangedCompanies}
              handleCompanySwitch={handleCompanySwitch}
              isOnBoarding={isOnBoarding}
            />
            <div className={styles.pageContent}>
              {children}
              {showPlanExpiredModal && (
                <div className={styles.planExpiredModal}>
                  <TrialExpired
                    companies={rearrangedCompanies}
                    handleCompanySwitch={handleCompanySwitch}
                  />
                </div>
              )}
              {/* Hiding tutorial for time being */}
              {!hideTutorial && showWelcomeModal && (
                <WelcomeModal
                  userName={user?.name}
                  closeHandler={closeWelcomeModal}
                  getStartedHandler={closeWelcomeModal}
                />
              )}
              {!hideTutorial && showTutorialModal && (
                <TutorialModal
                  isAdmin={user?.isAdmin}
                  isTeamAdmin={user.isTeamAdmin}
                  isSlackPlatform={isSlackPlatform}
                  closeHandler={closeTutorialModal}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.any,
  className: PropTypes.string
};

Layout.defaultProps = {
  className: null,
  pageTitle: ''
};

export default Layout;
