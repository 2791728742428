import { useParams } from 'react-router-dom';
import { Box, Tooltip, Table, Flex } from '@mantine/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IntegrationActionMenu from '../../../pages/Integrations/Connector/Components/ActionMenu';
import ActionMenu from 'components/ActionMenu';
import SourceIcon from 'pages/Integrations/Connector/Web/DataView/Components/SourceIcon';
import { stringDecoder } from 'utils/helpers';
import { isRetrainDisabled, selectSourceIcon } from 'pages/Integrations/utils';
import { formatDate } from 'utils/formatters';
import { ACCOUNT_BASED_CONNECTORS } from 'pages/ControlPanel/constants';
import { PROVIDERS } from 'utils/constants';
import ErrorIcon from 'assets/icons/error_icon.svg';
import styles from './styles.module.scss';

const Rows = (props) => {
  const {
    rowValues,
    showManageResources,
    hideDisconnect,
    actionMenuData,
    rowClickHandler,
    handleSitemapManageSource,
    hideRetrainNow,
    hideManageAutoRetrain,
    messageSourceDeleteModal,
    showManageSourceForWeb,
    showEditName,
    showManageSitemapSources,
    sourceIcon,
    metaPayload,
    disableRowClick
  } = props;

  const { provider } = useParams();

  function handleSourceNameClick(event, row) {
    row.handleOpenSource?.();
  }

  return (
    <Table.Tbody>
      {rowValues.map((row) => {
        const { isDisabled, message: customMessage } = isRetrainDisabled(
          row.lastTrainingStartedAt,
          provider
        );
        let status = row.status;
        let message = customMessage;
        const isSourceArchived = row.status === 'archived';
        if (isSourceArchived) {
          status = 'active';
          message =
            metaPayload.provider === PROVIDERS.GOOGLE
              ? 'Albus does not have access to this Google Drive file anymore. To retrain on it, remove this file and add it back.'
              : 'Cannot train on archived Slack channels.';
        }

        const disableDisconnect = !row.isSlackMessage && row.status === 'processing';
        const isCustomSource = provider === PROVIDERS.CUSTOM_SOURCES;
        const showRetryAdding = isCustomSource && row.status === 'error';

        return (
          <Table.Tr
            key={'rows' + row.id}
            className={classNames({
              [styles.clickableRow]: rowClickHandler && !disableRowClick && !row.disableManageSource
            })}
            onClick={() => rowClickHandler(row)}
          >
            {row.title && (
              <Table.Td className={styles.title}>
                {(row.type || row.sourceIcon || sourceIcon) && (
                  <SourceIcon
                    sourceIconStyles={styles.sourceIcon}
                    url={selectSourceIcon(row?.type) || row?.sourceIcon || sourceIcon}
                  />
                )}
                {typeof row.title === 'string' ? (
                  <Tooltip
                    p={15}
                    disabled={metaPayload.provider === PROVIDERS.WEB || row.title.length < 40}
                    label={decodeURIComponent(row?.tooltip)}
                    maw={404}
                  >
                    <span
                      onClick={(e) => handleSourceNameClick(e, row)}
                      className={classNames({
                        [styles.noUrlSourceTitle]: row.url === undefined,
                        [styles.teamsTableTitle]: row.isTeamTable || row.isMemberTable,
                        [styles.sourceTitle]: !row.isTeamTable && !row.isMemberTable && row.url,
                        underline:
                          !row.isTeamTable &&
                          !row.isMemberTable &&
                          !ACCOUNT_BASED_CONNECTORS.includes(metaPayload.provider)
                      })}
                    >
                      {stringDecoder(row.title)}
                    </span>
                  </Tooltip>
                ) : (
                  <span
                    onClick={(e) => handleSourceNameClick(e, row)}
                    className={classNames({
                      [styles.noUrlSourceTitle]: row.url === undefined,
                      [styles.teamsTableTitle]: row.isTeamTable || row.isMemberTable,
                      [styles.sourceTitle]: !row.isTeamTable && !row.isMemberTable,
                      underline:
                        !row.isTeamTable &&
                        !row.isMemberTable &&
                        !ACCOUNT_BASED_CONNECTORS.includes(metaPayload.provider)
                    })}
                  >
                    {stringDecoder(row.title)}
                  </span>
                )}
              </Table.Td>
            )}

            {row.sources && <Table.Td>{row.sources}</Table.Td>}
            {row.members && <Table.Td>{row.members}</Table.Td>}
            {row.domainName && <Table.Td>{row.domainName}</Table.Td>}
            {row.permission && <Table.Td>{row.permission}</Table.Td>}
            {(row.lastTrainingAttemptedAt || row.lastTrainingStartedAt) && (
              <Table.Td>
                <Flex align='center' gap={6}>
                  {row.lastTrainingAttemptedAt
                    ? formatDate(row.lastTrainingAttemptedAt, 'DD MMMM, YYYY')
                    : formatDate(row.lastTrainingStartedAt, 'DD MMMM, YYYY')}
                  {row.retrainError && (
                    <Tooltip label={row.retrainError}>
                      <img height={18} width={18} src={ErrorIcon} />
                    </Tooltip>
                  )}
                </Flex>
              </Table.Td>
            )}
            {provider !== PROVIDERS.GOOGLE && 'lastTrainedOn' in row && (
              <Table.Td>{row.lastTrainedOn || '-'}</Table.Td>
            )}
            {row.uploadBy && <Table.Td>{row.uploadBy}</Table.Td>}
            {status && (
              <Table.Td>
                <Box
                  className={classNames(
                    styles.statusContainer,
                    status === 'active' ? styles.inCompleted : styles.inProcess
                  )}
                >
                  {row.error && !isSourceArchived ? (
                    <Tooltip label={row.error} w={200}>
                      <span className='capitalize'>{status}</span>
                    </Tooltip>
                  ) : (
                    <span className='capitalize'>{status}</span>
                  )}
                </Box>
              </Table.Td>
            )}
            <td>
              {actionMenuData ? (
                <div onClick={(e) => e.stopPropagation()}>
                  <ActionMenu actionMenuData={actionMenuData} data={row} />
                </div>
              ) : (
                <IntegrationActionMenu
                  showRetryAdding={showRetryAdding}
                  item={row}
                  disableRetrain={isSourceArchived || isDisabled}
                  disableAutoRetrainMessage={message}
                  showManageResources={showManageResources}
                  hideDisconnect={hideDisconnect}
                  disableDisconnect={disableDisconnect}
                  disableAutoRetrain={isSourceArchived}
                  disableDisableAutoRetrain={isSourceArchived}
                  showManageSitemapSources={showManageSitemapSources}
                  handleSitemapManageSource={() => handleSitemapManageSource(row)}
                  hideRetrainNow={hideRetrainNow}
                  hideManageAutoRetrain={hideManageAutoRetrain}
                  messageSourceDeleteModal={messageSourceDeleteModal}
                  showManageSourceForWeb={showManageSourceForWeb}
                  showEditName={showEditName}
                  sourceIcon={sourceIcon}
                  metaPayload={metaPayload}
                />
              )}
            </td>
          </Table.Tr>
        );
      })}
    </Table.Tbody>
  );
};

Rows.propTypes = {
  rowValues: PropTypes.array.isRequired,
  hideDisconnect: PropTypes.bool,
  showManageResources: PropTypes.bool,
  actionMenuData: PropTypes.func,
  rowClickHandler: PropTypes.func,
  handleSitemapManageSource: PropTypes.func,
  hideRetrainNow: PropTypes.bool,
  hideManageAutoRetrain: PropTypes.bool,
  messageSourceDeleteModal: PropTypes.bool,
  showManageSourceForWeb: PropTypes.bool,
  showEditName: PropTypes.bool,
  showManageSitemapSources: PropTypes.bool,
  sourceIcon: PropTypes.string,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired,
  disableRowClick: PropTypes.bool
};

Rows.defaultProps = {
  handleAutoTrain: () => null,
  rowClickHandler: () => null,
  handleSitemapManageSource: () => null,
  hideRetrainNow: false,
  hideManageAutoRetrain: false,
  sourceIcon: '',
  disableRowClick: false
};

export default Rows;
