import { Text, Flex, TextInput, Select, Button } from '@mantine/core';
import PropTypes from 'prop-types';
import Announcement from 'components/Announcement';
import ViewChanger from '../../Components/ViewChanger';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as LinkWhiteIcon } from 'assets/link-white.svg';
import { SOURCE_TYPES } from 'pages/Integrations/utils';
import data from './data.json';
import styles from './styles.module.scss';

const Header = (props) => {
  const {
    searchValue,
    setSearchValue,
    viewType,
    setViewType,
    user,
    showUpdateModal,
    sourceType,
    setSourceType,
    pagination
  } = props;
  const placeholderText = sourceType === 'channel' ? 'Search for channels' : 'Search for messages';

  function howThisWorksClickHandler() {
    window.open(
      'https://support.springworks.in/portal/en/kb/albus/albus-for-slack/mark-important',
      '_blank'
    );
  }

  return (
    <div>
      <Announcement
        title='Albus trains on both channels and messages marked as important'
        textCopy='Albus trains on both entire channels and on specific messages on channels marked as important by admins. When he looks for an answer, he prioritizes messages marked as important over regular messages sent on a channel.'
      />
      <Flex mt={27} justify={'space-between'} align='flex-end' className={styles.container}>
        <TextInput
          className={styles.searchInput}
          placeholder={placeholderText}
          leftSection={<SearchIcon />}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Flex align='flex-end' className={styles.rightPart}>
          <Select
            classNames={{
              input: styles.selectInput,
              option: styles.selectOption,
              dropdown: styles.dropdown,
              label: styles.selectLabel
            }}
            label={<Text className={styles.label}>Source type</Text>}
            defaultValue={SOURCE_TYPES.CHANNELS}
            radius={4}
            width={150}
            value={sourceType}
            searchable={false}
            data={data.dropDownData}
            allowDeselect={false}
            onChange={(value) => setSourceType(value)}
          />
          <ViewChanger
            viewType={viewType}
            setViewType={setViewType}
            user={user}
            showUpdateModal={showUpdateModal}
            hideAddSourceButton={sourceType === SOURCE_TYPES.MESSAGES}
            customWrapperStyles={styles.customWrapperStyles}
            pagination={pagination}
          />

          {sourceType === SOURCE_TYPES.MESSAGES && (
            <Button
              className='primaryBtn '
              rightSection={<LinkWhiteIcon />}
              onClick={howThisWorksClickHandler}
            >
              How this works
            </Button>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

Header.defaultProps = {
  searchValue: '',
  setSearchValue: () => null,
  viewType: 'grid',
  setViewType: () => null,
  user: {},
  showUpdateModal: true,
  sourceType: 'message',
  setSourceType: () => null
};

Header.propTypes = {
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  user: PropTypes.object,
  showUpdateModal: PropTypes.bool,
  sourceType: PropTypes.string,
  setSourceType: PropTypes.func,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  })
};

export default Header;
