import { useState } from 'react';
import { Button, Flex, LoadingOverlay, Text } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetSettingsQuery, useGetTeamsQuery } from 'redux/services/settings';
import Layout from 'components/Layout';
import Google from './Google';
import Slack from './Slack';
import CustomSources from './Custom';
import Web from './Web';
import OtherIntegration from './OtherIntegrations';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { providerTitle } from '../utils';
import { NAVBAR_ROUTES, PROVIDERS, VIEW_TYPE, APIDECK_INTEGRATIONS } from 'utils/constants';
import styles from './connector.module.scss';

const Connector = () => {
  const { provider } = useParams();
  const navigate = useNavigate();
  const { isLoading: areSettingsLoading = true } = useGetSettingsQuery();

  const { user } = useSelector((state) => state.user);
  const [viewType, setViewType] = useState(VIEW_TYPE.GRID);

  const { teamId } = useParams();
  const {
    data: { teams = [] } = {
      teams: []
    },
    isFetching
  } = useGetTeamsQuery();
  const teamName = isFetching
    ? ''
    : teams.find((team) => team.teamId === teamId)?.name || 'Company wiki';

  return (
    <Layout
      pageTitle={
        <Flex style={{ alignItems: 'center' }}>
          <Button
            className={styles.navigateBackButton}
            onClick={() => {
              navigate(`${NAVBAR_ROUTES.INTEGRATIONS}/${teamId}`);
            }}
            variant='default'
          >
            <SVGIcon name={ICONS_LIST.LEFT_ARROW} height={18} width={18} />
          </Button>

          <Text className={styles.providerTitle}>
            Wiki &gt; {teamName} &gt; <span>{providerTitle(provider)}</span>
          </Text>
        </Flex>
      }
    >
      <LoadingOverlay visible={areSettingsLoading} />
      <div className={styles.pageContent}>
        {provider === PROVIDERS.GOOGLE ? (
          <Google viewType={viewType} setViewType={setViewType} user={user} />
        ) : null}

        {provider === PROVIDERS.SLACK ? (
          <Slack viewType={viewType} setViewType={setViewType} user={user} />
        ) : null}

        {provider === PROVIDERS.WEB && (
          <Web
            viewType={viewType}
            setViewType={setViewType}
            user={user}
            teamName={teamName}
            teamId={teamId}
          />
        )}

        {provider === PROVIDERS.CUSTOM_SOURCES ? (
          <CustomSources viewType={viewType} setViewType={setViewType} user={user} />
        ) : null}

        {APIDECK_INTEGRATIONS.includes(provider) ? (
          <OtherIntegration
            viewType={viewType}
            setViewType={setViewType}
            user={user}
            provider={provider}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export default Connector;
