import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Flex, Image, Stepper } from '@mantine/core';
import AlbusWhiteLogo from '../../../../assets/albus-light-logo.svg';
import AlbusDarkLogo from '../../../../assets/albus-new-logo.svg';
import StepperIcon from 'assets/icons/stepper.svg';
import styles from './styles.module.scss';

const Header = (props) => {
  const { showLeftPanel, active, setActive, logout } = props;
  const stepClasses = {
    0: styles.contentIntroduction,
    1: styles.contentConnections,
    2: styles.contentAddSource,
    3: styles.contentAskQuestion
  };
  return (
    <Flex className={classNames(styles.wrapper, { [styles.askAQuestionStyles]: active === 3 })}>
      <Image
        src={showLeftPanel ? AlbusWhiteLogo : AlbusDarkLogo}
        w={120}
        className={classNames(styles.albusLogo, { [styles.hidden]: active === 3 })}
      />
      <Flex>
        <Stepper
          classNames={{
            root: styles.stepperRoot,
            content: stepClasses[active],
            separator: styles.separator,
            stepIcon: styles.stepIcon
          }}
          className={showLeftPanel && styles.stepperCenter}
          active={active}
          onStepClick={setActive}
        >
          <Stepper.Step allowStepSelect={false} icon={<img src={StepperIcon} />}>
            Introduction
          </Stepper.Step>
          <Stepper.Step allowStepSelect={false} icon={<img src={StepperIcon} />}>
            Connect app
          </Stepper.Step>
          <Stepper.Step allowStepSelect={false} icon={<img src={StepperIcon} />}>
            Add sources
          </Stepper.Step>
          <Stepper.Step allowStepSelect={false} icon={<img src={StepperIcon} />}>
            Ask question
          </Stepper.Step>
        </Stepper>
      </Flex>
      <Button
        onClick={logout}
        className={classNames(styles.signOutBtn, { [styles.hidden]: active === 3 })}
      >
        Sign out
      </Button>
    </Flex>
  );
};

Header.defaultProps = {
  showLeftPanel: false,
  logout: () => null
};

Header.propTypes = {
  showLeftPanel: PropTypes.bool,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
  logout: PropTypes.func
};

export default Header;
