import PropTypes from 'prop-types';
import { Flex, Text, Tooltip } from '@mantine/core';
import Table from '../../../../../components/Table';
import SourceGrid from '../../Components/SourceGrid';
import { trainedAtTableHeader } from '../../Notion/DataView';
import LinkIcon from 'assets/link.svg';
import { retrainFailureTooltip, truncateName } from 'pages/Integrations/utils';
import { formatDate } from 'utils/formatters';
import { ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS, VIEW_TYPE } from 'utils/constants';
import styles from '../ChannelsDataView/styles.module.scss';

const MessageDataView = (props) => {
  const { viewType, messages, user, isLoading, pagination, pageCount, totalCount, metaPayload } =
    props;

  const formattedRows = messages.map((list) => ({
    id: list.id,
    title: (
      <Flex gap={7} w='fit-content' onClick={() => list.url && window.open(list.url, '_blank')}>
        {truncateName(list.name, 50)}
        <img src={LinkIcon} />
      </Flex>
    ),
    name: (
      <Tooltip
        label={
          <Text size='xs'>
            {truncateName(list.name, 150)} &nbsp;
            <img src={LinkIcon} />
          </Text>
        }
        w={400}
        p={12}
        position='top'
        disabled={list.name.length < 70}
      >
        <Text>
          {truncateName(list.name, 70)}
          &nbsp;
          <img src={LinkIcon} />
        </Text>
      </Tooltip>
    ),
    url: list.url,
    tooltip: list.name,
    date: formatDate(list.lastTrainingStartedAt),
    uploadBy: <div className={styles.uploaderName}>{list?.uploadedBy || 'User'}</div>,
    status: list.status,
    admin: list.admin,
    trainingFrequency: list.trainingFrequency,
    lastTrainingStartedAt: list.lastTrainingStartedAt || list.createdAt,
    lastTrainingAttemptedAt: list.lastTrainingAttemptedAt || list.createdAt,
    error: list.error,
    handleOpenSource: () => list.url && window.open(list.url, '_blank'),
    isSlackMessage: true,
    description: list.description,
    retrainError: retrainFailureTooltip(
      list.status,
      list.lastTrainType,
      list.lastTrainingAttemptedAt || list.lastTrainingStartedAt
    )
  }));

  return viewType === VIEW_TYPE.GRID ? (
    <SourceGrid
      items={formattedRows}
      user={user}
      hideCreatedAt={true}
      pagination={pagination}
      pageCount={pageCount}
      isLoading={isLoading}
      showHoverEffect={true}
      hideRetrainNow={true}
      hideManageAutoRetrain={true}
      messageSourceDeleteModal={true}
      metaPayload={metaPayload}
    />
  ) : (
    <Table
      headers={['Source', trainedAtTableHeader, 'Added by', 'Status', '']}
      rows={formattedRows}
      hideCreatedAt={true}
      pagination={pagination}
      pageCountFromProp={pageCount}
      pageLimitFromProp={ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS}
      isLoading={isLoading}
      hideRetrainNow={true}
      hideManageAutoRetrain={true}
      messageSourceDeleteModal={true}
      totalCount={totalCount}
      disableRowClick
    />
  );
};

MessageDataView.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  messages: PropTypes.array,
  user: PropTypes.object.isRequired,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  showUpdateModal: PropTypes.bool,
  totalCount: PropTypes.number.isRequired,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired
};

export default MessageDataView;
