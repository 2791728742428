import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Accordion } from '@mantine/core';
import classNames from 'classnames';
import { CONNECTOR_CATEGORIES_LIST } from 'pages/ControlPanel/constants';
import { NAVBAR_ROUTES } from 'utils/constants';
import Arrow from 'assets/icons/new-arrow.svg';
import styles from './styles.module.scss';

const ConnectionsList = (props) => {
  const {
    connectorsByCategories,
    setActiveCategory,
    hideChevron,
    setSelectedConnection,
    searchTerm
  } = props;

  const scrollElement = document.getElementById('scroll-target');
  const navigate = useNavigate();

  useEffect(() => {
    if (scrollElement) {
      scrollElement?.addEventListener('scroll', handleScroll);
    }
    return () => {
      scrollElement?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollElement, connectorsByCategories]);

  useEffect(() => {
    const scrollToCategoryFromURL = () => {
      const category = window.location.hash.replace('#', '');
      if (category) {
        const elementId = decodeURIComponent(category);
        setActiveCategory(elementId);
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
          // MutationObserver to wait for the element to be loaded
          const observer = new MutationObserver((mutationsList, observer) => {
            for (const mutation of mutationsList) {
              if (mutation.type === 'childList') {
                const element = document.getElementById(elementId);
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  observer.disconnect();
                  break;
                }
              }
            }
          });
          observer.observe(document.body, { childList: true, subtree: true });
        }
      }
    };
    const handlePopstate = () => scrollToCategoryFromURL();
    scrollToCategoryFromURL();
    window.addEventListener('popstate', handlePopstate);
    return () => window.removeEventListener('popstate', handlePopstate);
  }, []);

  const handleScroll = () => {
    //category highlighting logic
    let activeCat = [];
    const categories = connectorsByCategories.map((conn) => conn.name);
    categories.forEach((cat) => {
      const element = document.getElementById(cat);
      let rect;
      if (element !== null && element !== undefined) {
        rect = element.getBoundingClientRect();
      }

      if (
        rect?.top >= 0 &&
        rect?.left >= 0 &&
        rect?.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
      ) {
        activeCat?.push(cat);
      }
    });

    activeCat?.length && setActiveCategory(activeCat[0]);
    if (activeCat[0]) {
      navigate(NAVBAR_ROUTES.SETTINGS_TABS.CONNECTIONS + '#' + activeCat[0], { replace: true });
    }
  };

  const connectorCard = (conn) => {
    return (
      <div key={conn.title} onClick={() => setSelectedConnection(conn)} className={styles.item}>
        <div className={styles.itemName}>
          <img src={conn.icon} width={48} alt='connector icon' loading='lazy' />
          {conn.isConnected && <div className={styles.connectedTag}>Connected</div>}
        </div>
        <div className={styles.content}>
          <span className={styles.title}>{conn.title} </span>
        </div>
        {conn.subTitle && <div className={styles.subtitle}>{conn.subTitle}</div>}
        <img src={Arrow} height={14} width={21} alt='arrow icon' loading='lazy' />
      </div>
    );
  };

  const cardsGroup = (cat) => {
    return (
      <Accordion.Item key={cat.name} value={cat.name} className={styles.accordionItem}>
        <div name={cat.name} id={cat.name} key={cat.name} className={styles.group}>
          <Accordion.Control
            className={classNames(styles.accordionControl, { [styles.disabled]: hideChevron })}
          >
            <p className={styles.connectorName}>
              {cat.name}
              <span className={styles.searchTerm}>
                {searchTerm && `(${cat.connectors.length})`}
              </span>
            </p>
          </Accordion.Control>
          <Accordion.Panel>
            <div className={styles.cards}>{cat.connectors.map((conn) => connectorCard(conn))}</div>
          </Accordion.Panel>
        </div>
      </Accordion.Item>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Accordion
        multiple
        defaultValue={[
          ...Object.values(CONNECTOR_CATEGORIES_LIST),
          'Search result',
          'My connections',
          'All'
        ]}
        classNames={{
          chevron: styles.chevron,
          control: styles.accordianControl
        }}
      >
        {connectorsByCategories.map((cat) => cardsGroup(cat))}
      </Accordion>
    </div>
  );
};

ConnectionsList.defaultProps = {
  searchTerm: ''
};

ConnectionsList.propTypes = {
  connectorsByCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      connectors: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          isConnected: PropTypes.bool.isRequired,
          isActive: PropTypes.bool.isRequired,
          autoConnect: PropTypes.bool
        })
      )
    })
  ).isRequired,
  setSelectedConnection: PropTypes.func.isRequired,
  setActiveCategory: PropTypes.func.isRequired,
  hideChevron: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string
};

export default ConnectionsList;
