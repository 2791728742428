import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { AppShell, Group, Avatar, Title } from '@mantine/core';
import CompanySwitch from '../CompanySwitch';
import RemainingQuestionsBanner from './RemainingQuestionsBanner';
import FallbackImage from 'assets/company-fallback.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_warning.svg';
import styles from './layout.module.scss';

const Header = (props) => {
  const { user, pageTitle, companies, handleCompanySwitch, isOnBoarding } = props;

  return (
    <AppShell>
      <AppShell.Header className={styles.mantineHeader}>
        <div className={styles.headerContainer}>
          <Title order={3}>{pageTitle} </Title>
          <Group
            justify='apart'
            className={classNames(styles.teamName, { [styles.hidden]: isOnBoarding })}
          >
            <RemainingQuestionsBanner user={user} ErrorIcon={<ErrorIcon />} />
            {companies?.length < 2 ? (
              <>
                <Avatar
                  classNames={{ root: styles.headerAvatar, image: styles.avatarImage }}
                  size={42}
                  src={user?.team?.photo || FallbackImage}
                />
                <Title className={styles.title}>{user?.team?.name || 'Your Team'}</Title>
              </>
            ) : (
              <CompanySwitch
                companies={companies}
                user={user}
                handleCompanySwitch={handleCompanySwitch}
              />
            )}
          </Group>
        </div>
      </AppShell.Header>
    </AppShell>
  );
};

Header.default = {
  companies: [],
  handleCompanySwitch: () => null,
  isOnBoarding: false
};

Header.propTypes = {
  user: PropTypes.object.isRequired,
  pageTitle: PropTypes.any,
  companies: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, logo: PropTypes.string, id: PropTypes.string })
  ),
  handleCompanySwitch: PropTypes.func,
  isOnBoarding: PropTypes.bool
};

export default Header;
