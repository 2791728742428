import NoSearchResultIcon from 'assets/icons/no_search_result.svg';
import styles from './styles.module.scss';

const NoSearchResult = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.searchlabel}>
        Search result <span>(0)</span>
      </div>
      <div className={styles.body}>
        <img src={NoSearchResultIcon} alt='No search result' />
        <div>No connections found</div>
      </div>
    </div>
  );
};

export default NoSearchResult;
