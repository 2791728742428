import { Badge, Checkbox, Flex, Menu, Table, Text, Tooltip, UnstyledButton } from '@mantine/core';
import PropTypes from 'prop-types';
import Footer from '../Footer';
import ConfirmationModal from '../ConfimationModal';
import { truncateName } from 'pages/Integrations/utils';
import { ERROR_TOOLTIPS, SOURCES_STATUS, SOURCE_ACTION } from '../../../constants';
import WarningIcon from 'assets/icons/error_warning_orange.svg';
import { ReactComponent as ActionIcon } from 'assets/icons/3-v-dots.svg';
import styles from './styles.module.scss';

const View = (props) => {
  const {
    showConfirmationModalData,
    setShowConfirmationModalData,
    handleConfirm,
    selectedSources,
    tab,
    handleGlobalCheckClick,
    handleSingleCheckClick,
    sources,
    isAllSelected,
    handleScrollCapture,
    count,
    loading,
    excludedSubDocs,
    handleExclude
  } = props;

  return (
    <div className={styles.wrapper}>
      {showConfirmationModalData.open && (
        <ConfirmationModal
          action={showConfirmationModalData.action}
          isMulty={selectedSources?.length > 1}
          handleConfirm={handleConfirm}
          setShowConfirmationModalData={setShowConfirmationModalData}
          loading={loading}
        />
      )}
      <Table className={styles.table}>
        <Table.Thead>
          <Table.Tr>
            {tab !== SOURCES_STATUS.PROCESSING && (
              <Table.Th w='10px' key='checkbox'>
                <Checkbox
                  onChange={handleGlobalCheckClick}
                  checked={isAllSelected && !excludedSubDocs.length}
                  color='#1CA555'
                  radius={4}
                  classNames={{ input: styles.checkboxCursor }}
                />
              </Table.Th>
            )}

            <Table.Th key='webpages'>Web pages</Table.Th>
            <Table.Th w='50%' key='url'>
              URL
            </Table.Th>
            <Table.Th key='status'>Status</Table.Th>
            <Table.Th key='action'></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody onScrollCapture={handleScrollCapture}>
          {sources?.map((source) => {
            let status = (
              <Badge
                color='#13A719'
                variant='outline'
                bg='#E6FFE0'
                tt='capitalize'
                fw='lighter'
                fs='12px'
                p='sm'
              >
                Active
              </Badge>
            );
            if (tab === SOURCES_STATUS.PROCESSING) {
              status = (
                <Badge
                  color='#FF820F'
                  variant='outline'
                  bg='#FFEBD8'
                  tt='capitalize'
                  fw='lighter'
                  fs='12px'
                  p='sm'
                >
                  Processing
                </Badge>
              );
            }
            if (tab === SOURCES_STATUS.EXCLUDED) {
              status = (
                <Badge
                  color='#969696'
                  variant='outline'
                  bg='#CECECE'
                  tt='capitalize'
                  fw='lighter'
                  fs='12px'
                  p='sm'
                >
                  Excluded
                </Badge>
              );
            }
            if (tab === SOURCES_STATUS.ERROR) {
              status = (
                <Flex align='center'>
                  {/*This Flex is to center align the tooltip*/}
                  <Tooltip
                    label={ERROR_TOOLTIPS[source.error]}
                    radius='4px'
                    position='top-center'
                    p='10px'
                  >
                    <Flex align='center' gap={3}>
                      <img src={WarningIcon} />
                      <Text c='#F58A5D' fw='lighter' size='12px' tt='capitalize'>
                        {source.error}
                      </Text>
                    </Flex>
                  </Tooltip>
                </Flex>
              );
            }
            return (
              <Table.Tr key={source._id}>
                {tab !== SOURCES_STATUS.PROCESSING && (
                  <Table.Td>
                    <Checkbox
                      onChange={() => handleSingleCheckClick(source)}
                      checked={selectedSources?.includes(source._id)}
                      value={source._id}
                      color='#1CA555'
                      radius={4}
                      classNames={{ input: styles.checkboxCursor }}
                    />
                  </Table.Td>
                )}
                <Table.Td>
                  <Flex justify='start'>
                    <Tooltip
                      disabled={source.name.length <= 80}
                      w='fit-content'
                      label={<p className='wordBreak'>{source.name}</p>}
                      multiline
                    >
                      <Text fs='14px'>{truncateName(source.name, 80)}</Text>
                    </Tooltip>
                  </Flex>
                </Table.Td>
                <Table.Td>
                  <Tooltip
                    disabled={source.documentId.length <= 120}
                    maw='50%'
                    label={<p className='wordBreak'>{source.documentId}</p>}
                    multiline
                  >
                    <Text fs='14px'>{truncateName(source.documentId, 120)}</Text>
                  </Tooltip>
                </Table.Td>
                <Table.Td>
                  <Text fs='14px'>{status}</Text>
                </Table.Td>
                <Table.Td align='center'>
                  {tab !== SOURCES_STATUS.PROCESSING && (
                    <Menu width='fit-content' radius='md'>
                      <Menu.Target>
                        <UnstyledButton
                          pl={10}
                          pr={10}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <ActionIcon />
                        </UnstyledButton>
                      </Menu.Target>
                      <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
                        {tab !== SOURCES_STATUS.EXCLUDED && (
                          <>
                            <Menu.Item
                              c='#565656'
                              onClick={() =>
                                setShowConfirmationModalData({
                                  open: true,
                                  action: SOURCE_ACTION.RETRAIN,
                                  data: source._id
                                })
                              }
                              pr={10}
                              pl={10}
                            >
                              Retrain
                            </Menu.Item>
                            <Menu.Item
                              c='#565656'
                              onClick={() => handleExclude(source)}
                              pr={10}
                              pl={10}
                            >
                              Exclude
                            </Menu.Item>
                          </>
                        )}
                        {tab === SOURCES_STATUS.EXCLUDED && (
                          <Menu.Item
                            c='#565656'
                            onClick={() =>
                              setShowConfirmationModalData({
                                open: true,
                                action: SOURCE_ACTION.INCLUDE,
                                data: source._id
                              })
                            }
                            pr={10}
                            pl={10}
                          >
                            Include
                          </Menu.Item>
                        )}
                      </Menu.Dropdown>
                    </Menu>
                  )}
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
      {!!selectedSources?.length && (
        <Footer
          handleExclude={handleExclude}
          handleInclude={() =>
            setShowConfirmationModalData({
              open: true,
              action: SOURCE_ACTION.INCLUDE
            })
          }
          handleRetrain={() =>
            setShowConfirmationModalData({
              open: true,
              action: SOURCE_ACTION.RETRAIN
            })
          }
          tab={tab}
          count={count}
        />
      )}
    </div>
  );
};

View.defaultProps = {
  loading: false
};

View.propTypes = {
  showConfirmationModalData: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    data: PropTypes.oneOf[(PropTypes.object, null)],
    action: PropTypes.oneOf[(PropTypes.string, null)]
  }),
  setShowConfirmationModalData: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  selectedSources: PropTypes.arrayOf(PropTypes.string),
  tab: PropTypes.string.isRequired,
  handleGlobalCheckClick: PropTypes.func.isRequired,
  handleSingleCheckClick: PropTypes.func.isRequired,
  sources: PropTypes.array.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  handleScrollCapture: PropTypes.func.isRequired,
  count: PropTypes.number,
  loading: PropTypes.bool,
  excludedSubDocs: PropTypes.array.isRequired,
  handleExclude: PropTypes.func.isRequired
};

export default View;
