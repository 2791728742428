import { useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { Flex, TextInput, Select, Loader } from '@mantine/core';
import PropTypes from 'prop-types';
import SourceGrid from '../../Components/SourceGrid';
import ViewChanger from '../../Components/ViewChanger';
import Table from '../../../../../components/Table';
import EmptyState from '../../Components/EmptyState';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import CustomSourcesIcon from 'assets/icons/custom_sources.svg';
import { formatDate } from 'utils/formatters';
import { retrainFailureTooltip, truncateName } from 'pages/Integrations/utils';
import { ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS, VIEW_TYPE } from 'utils/constants';
import styles from './styles.module.scss';

const SourcesDataView = (props) => {
  const {
    viewType,
    setViewType,
    sources,
    user,
    isLoading,
    pagination,
    pageCount,
    searchValue,
    setSearchValue,
    debouncedValue,
    metaPayload,
    totalCount
  } = props;
  const [fileType, setFileType] = useState('all');
  const isSmallerThanSm = useMediaQuery('(max-width: 767px)');

  const filteredSources = sources.filter((source) => {
    const typeMatch = fileType === 'all' || source.type === fileType;
    return typeMatch;
  });

  const selectValues = sources.reduce(
    (acc, source) => {
      if (source.type && !acc.includes(source.type)) {
        acc.push(source.type);
      }
      return acc;
    },
    ['all']
  );

  const formattedRows = filteredSources.map((list) => {
    return {
      id: list.id,
      title: truncateName(list.name ? decodeURIComponent(list.name) : list.url, 40),
      name: list.name ? list.name : list.url,
      url: list.url,
      type: list.type,
      date: formatDate(list.lastTrainingStartedAt),
      uploadBy: <div className={styles.uploaderName}>{list?.uploadedBy || 'User'}</div>,
      status: list.status,
      admin: list.admin,
      trainingFrequency: list.trainingFrequency,
      lastTrainingStartedAt: list.lastTrainingStartedAt || list.createdAt,
      lastTrainingAttemptedAt: list.lastTrainingAttemptedAt || list.createdAt,
      error: list.error,
      description: list.description,
      retrainError: retrainFailureTooltip(
        list.status,
        list.lastTrainType,
        list.lastTrainingAttemptedAt || list.lastTrainingStartedAt
      ),
      handleOpenSource: () => list.url && window.open(list.url, '_blank'),
      tooltip: list.name.length > 40 && list.name
    };
  });

  return (
    <>
      <Flex
        mt={27}
        justify='space-between'
        align='flex-end'
        style={{ flexDirection: isSmallerThanSm ? 'column' : 'row' }}
      >
        <TextInput
          className={styles.searchInput}
          placeholder='Search for sources'
          leftSection={<SearchIcon />}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <Flex align='flex-end'>
          <Select
            label='Filter by format'
            placeholder='Select One'
            defaultValue='all'
            radius={4}
            value={fileType}
            searchable={false}
            data={selectValues}
            onChange={(value) => {
              setFileType(value);
            }}
            allowDeselect={false}
            classNames={{ label: styles.selectLabel }}
          />

          <ViewChanger
            viewType={viewType}
            setViewType={setViewType}
            user={user}
            hideAddSourceButton={!sources?.length}
            pagination={pagination}
          />
        </Flex>
      </Flex>

      {sources.length ? (
        viewType === VIEW_TYPE.GRID ? (
          <SourceGrid
            hideRetrainNow={true}
            items={formattedRows}
            user={user}
            hideCreatedAt={true}
            sourceIcon={CustomSourcesIcon}
            pagination={pagination}
            pageCount={pageCount}
            isLoading={isLoading}
            hideManageAutoRetrain={true}
            metaPayload={metaPayload}
          />
        ) : (
          <Table
            headers={['Source', 'Added on', 'Uploaded by', 'Status', '']}
            rows={formattedRows}
            hideCreatedAt={true}
            pagination={pagination}
            pageCountFromProp={pageCount}
            pageLimitFromProp={ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS}
            isLoading={isLoading}
            sourceIcon={CustomSourcesIcon}
            hideManageAutoRetrain={true}
            totalCount={totalCount}
            metaPayload={metaPayload}
            hideRetrainNow={true}
            disableRowClick
          />
        )
      ) : isLoading ? (
        <Flex align='center' justify='center' h='55vh'>
          <Loader />
        </Flex>
      ) : debouncedValue ? (
        <EmptyState hideButton text={`No sources matching "${searchValue}" found.`} />
      ) : (
        <EmptyState />
      )}
    </>
  );
};

SourcesDataView.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  sources: PropTypes.array,
  user: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  }).isRequired,
  pageCount: PropTypes.PropTypes.number.isRequired,
  searchValue: PropTypes.PropTypes.string.isRequired,
  setSearchValue: PropTypes.PropTypes.func.isRequired,
  debouncedValue: PropTypes.PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired
};

export default SourcesDataView;
