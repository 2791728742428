import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table as MantineTable, Pagination, Flex, Text, Skeleton } from '@mantine/core';
import Header from './Header';
import Rows from './Rows';
import { maybePluralize } from 'utils/utilities';
import { DOCS_PER_PAGE } from 'utils/constants';
import styles from './styles.module.scss';

const Table = (props) => {
  const {
    headers,
    rows,
    showManageResources,
    hideDisconnect,
    actionMenuData,
    rowClickHandler,
    handleSitemapManageSource,
    pagination,
    pageCountFromProp,
    pageLimitFromProp,
    isLoading,
    hideRetrainNow,
    hideManageAutoRetrain,
    messageSourceDeleteModal,
    showManageSourceForWeb,
    showEditName,
    showManageSitemapSources,
    sourceIcon,
    metaPayload,
    totalCount,
    disableRowClick
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState(rows.slice(0, DOCS_PER_PAGE));
  const [pageCount, setPageCount] = useState(Math.ceil(rows.length / DOCS_PER_PAGE));
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (page) => {
    setActivePage(page);
    const startIndex = (page - 1) * DOCS_PER_PAGE;
    const endIndex = startIndex + DOCS_PER_PAGE;
    setRowsPerPage(rows.slice(startIndex, endIndex));
  };

  useEffect(() => {
    handlePageChange(activePage);
    setPageCount(Math.ceil(rows.length / DOCS_PER_PAGE));
  }, [rows]);

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.tableContainer}>
        <MantineTable className={styles.documentsListTable}>
          <Header headers={headers} />
          {isLoading ? (
            <MantineTable.Tbody>
              <MantineTable.Tr>
                {headers.map((i) => (
                  <MantineTable.Td key={i}>
                    <Skeleton height={30} />
                  </MantineTable.Td>
                ))}
              </MantineTable.Tr>
            </MantineTable.Tbody>
          ) : (
            <Rows
              actionMenuData={actionMenuData}
              rowValues={pagination?.active ? rows : rowsPerPage}
              showManageResources={showManageResources}
              hideDisconnect={hideDisconnect}
              rowClickHandler={rowClickHandler}
              handleSitemapManageSource={handleSitemapManageSource}
              isLoading={isLoading}
              hideRetrainNow={hideRetrainNow}
              hideManageAutoRetrain={hideManageAutoRetrain}
              messageSourceDeleteModal={messageSourceDeleteModal}
              showManageSourceForWeb={showManageSourceForWeb}
              showEditName={showEditName}
              showManageSitemapSources={showManageSitemapSources}
              sourceIcon={sourceIcon}
              metaPayload={metaPayload}
              disableRowClick={disableRowClick}
            />
          )}
        </MantineTable>
      </div>
      <Flex className={styles.paginationWrapper}>
        <Pagination
          total={pageCountFromProp || pageCount}
          onChange={pagination?.setPage || handlePageChange}
          value={pagination?.active || activePage}
        />
        {pageLimitFromProp ? (
          <Text>
            {(pagination ? pagination.active : activePage) === Math.ceil(totalCount / DOCS_PER_PAGE)
              ? totalCount
              : rowsPerPage.length}
            &nbsp;of {totalCount} {maybePluralize(totalCount, 'result')}
          </Text>
        ) : (
          <Text>
            {DOCS_PER_PAGE * (activePage - 1) + rowsPerPage.length} of {rows.length}&nbsp;
            {maybePluralize(rows.length, 'result')}
          </Text>
        )}
      </Flex>
    </div>
  );
};

Table.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  hideDisconnect: PropTypes.bool,
  showManageResources: PropTypes.bool,
  actionMenuData: PropTypes.func,
  rowClickHandler: PropTypes.func,
  handleSitemapManageSource: PropTypes.func,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  }),
  pageCountFromProp: PropTypes.number,
  pageLimitFromProp: PropTypes.number,
  isLoading: PropTypes.bool,
  hideRetrainNow: PropTypes.bool,
  hideManageAutoRetrain: PropTypes.bool,
  messageSourceDeleteModal: PropTypes.bool,
  showManageSourceForWeb: PropTypes.bool,
  showEditName: PropTypes.bool,
  showManageSitemapSources: PropTypes.bool,
  sourceIcon: PropTypes.string,
  totalCount: PropTypes.number,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired,
  disableRowClick: PropTypes.bool
};

Table.defaultProps = {
  handleSitemapManageSource: () => null,
  rowClickHandler: () => null,
  pagination: {},
  isLoading: false,
  hideRetrainNow: false,
  hideManageAutoRetrain: false,
  sourceIcon: '',
  metaPayload: {
    provider: '',
    params: {}
  }
};

export default Table;
